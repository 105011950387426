import {ref, type Ref} from 'vue';

type FunctionType = (...args: any[]) => void;

export function useDebounce() {
    return function debounce(func: FunctionType, wait: number = 500): FunctionType {
        const timeout: Ref<NodeJS.Timeout | null> = ref(null);

        return function (...args: any[]) {
            if (timeout.value) {
                clearTimeout(timeout.value);
            }
            timeout.value = setTimeout(() => {
                func(...args);
            }, wait);
        };
    }
}
